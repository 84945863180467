import React, { useEffect, useState, useRef } from "react";
import UserWrapper from "./style";
import PageTitle from "components/common/PageTitle";
import { connect } from "react-redux";
import Toaster from "../../components/common/Toaster";
import ClassForm from "../../components/class/ClassForm";
import Api from "helper/Api";
import moment from "moment";
import { Uploader } from "../../util/upload"
import UploadActions from "../../redux/fileUpload/actions";

const { progress, complete } = UploadActions;


const ClassEdit = (props) => {
  const hasParam = props.match.params.id;

  const [editedData, setEditedData] = useState(null);

  const [loading, setloading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [loader, setLoader] = useState(false);
  const [uploader, setUploader] = useState(undefined)

  const toaster = useRef();

  useEffect(() => {
    if (hasParam) {
      Api("GET", `class-info/view/${hasParam}`)
        .then((res) => {
          // let ingredientsArray = [];
          const mealArr = [];
          res.data.data.class_meal_icons.map((res) => {
            return mealArr.push(res.meal_icon.id.toString());
          });
          // let splitData = res.data.data.ingredients.split(",");
          // splitData.map((result) => {
          //   return ingredientsArray.push(result);
          // });
          res.data.data.mealType = mealArr;
          // res.data.data.ingredients = ingredientsArray;
          setEditedData(res.data.data);
          setLoader(false);
        })
        .catch((err) => {
          setLoader(false);
        });
    } else {
      setLoader(false);
    }
  }, []);

  const generateRandomString = (length = 8) => {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  const submitFormHandler = (data, action) => {

    if (action === "add") {
      let params = {
        name: data.name,
        class_type: data.classType,
        description: data.description,
        chef_id: data.chef,
        class_date: data.date ? moment(moment(data.date).format("YYYY-MM-DD") +
          " " +
          moment(data.time).format("HH:mm:ss")).utc().format("YYYY-MM-DD") : "",
        class_time: data.time ? moment(moment(data.date).format("YYYY-MM-DD") +
          " " +
          moment(data.time).format("HH:mm:ss")).utc().format("HH:mm:ss") : "",
        duration: data.videoDuration,
        instructions: JSON.stringify(data.instructions),
        ingredients: JSON.stringify(data.ingredients),
        image: data.photo,
        meal_icon_id: data.mealType,
        recipe_name_start_time: data.recipe_name_start_time ? data.recipe_name_start_time.format("HH:mm:ss") : "",
        recipe_name_end_time: data.recipe_name_end_time ? data.recipe_name_end_time.format("HH:mm:ss") : "",
        ingredient_start_time: data.ingredient_start_time ? data.ingredient_start_time.format("HH:mm:ss") : "",
        ingredient_end_time: data.ingredient_end_time ? data.ingredient_end_time.format("HH:mm:ss") : "",
        video_link: ""
      };

      if (data.videoName) {
        let ext = data.videoName.name.split('.').pop();
        let videoFileName = generateRandomString(8) + "_" + moment().format('x');
        params.video_name = videoFileName + "." + ext;

        // video upload
        const videoUploaderOptions = {
          fileName: videoFileName,
          file: data.videoName,
        }
        const uploader = new Uploader(videoUploaderOptions, props)
        setUploader(uploader)
        let percentage = undefined
        uploader
          .onProgress(({ percentage: newPercentage, fileName: fileName }) => {
            // to avoid the same percentage to be logged twice
            if (newPercentage !== percentage) {
              percentage = newPercentage
              console.log(`${percentage}%`, `${fileName}`)
            }
          })
          .onError((error) => {
            // setFile(undefined)
            console.error(error)
          })

        props.progress({ fileName: videoFileName, status: 1 })

        uploader.start()
        // end video upload
      }

      const formData = new FormData();
      for (let key in params) {
        formData.append(key, params[key]);
      }
      setloading(true);
      setDisabled(true);
      Api("POST", "class-info/add", formData)
        .then((res) => {
          if (res.data.status) {
            if (toaster.current) toaster.current.success(res.data.message);
            setTimeout((res) => {
              props.history.push("/class");
            }, 1000);
            setloading(false);
            setDisabled(false);
          } else {
            if (toaster.current) toaster.current.error(res.data.message);
            setloading(false);
            setDisabled(false);
          }
        })
        .catch((err) => {
          if (toaster.current) toaster.current.error(err.response);
          setloading(false);
          setDisabled(false);
        });
    } else if (action === "edit") {
      const Ingredients = data.ingredients.map((res) => {
        return res.ingredient_id
          ? {
            id: res.id,
            ingredient_id: res.ingredient_id,
            measurement: res.measurement,
          }
          : {
            name: res.name,
            measurement: res.measurement,
          };
      });

      let params = {
        id: data.id,
        name: data.name,
        class_type: data.classType,
        description: data.description,
        chef_id: data.chef,
        class_date: data.date ? moment(moment(data.date).format("YYYY-MM-DD") +
          " " +
          moment(data.time).format("HH:mm:ss")).utc().format("YYYY-MM-DD") : "",
        class_time: data.time ? moment(moment(data.date).format("YYYY-MM-DD") +
          " " +
          moment(data.time).format("HH:mm:ss")).utc().format("HH:mm:ss") : "",
        duration: data.videoDuration,
        instructions: JSON.stringify(data.instructions),
        ingredients: JSON.stringify(Ingredients),
        image: data.photo,
        meal_icon_id: data.mealType,
        recipe_name_start_time: data.recipe_name_start_time ? data.recipe_name_start_time.format("HH:mm:ss") : "",
        recipe_name_end_time: data.recipe_name_end_time ? data.recipe_name_end_time.format("HH:mm:ss") : "",
        ingredient_start_time: data.ingredient_start_time ? data.ingredient_start_time.format("HH:mm:ss") : "",
        ingredient_end_time: data.ingredient_end_time ? data.ingredient_end_time.format("HH:mm:ss") : "",
        video_link: ""
      };

      if (typeof data.videoName == "object") {
        let ext = data.videoName.name.split('.').pop();
        let videoFileName = generateRandomString(8) + "_" + moment().format('x');
        params.video_name = videoFileName + "." + ext;

        // video upload
        const videoUploaderOptions = {
          fileName: videoFileName,
          file: data.videoName,
        }
        const uploader = new Uploader(videoUploaderOptions, props)
        setUploader(uploader)
        let percentage = undefined
        uploader
          .onProgress(({ percentage: newPercentage, fileName: fileName }) => {
            // to avoid the same percentage to be logged twice
            if (newPercentage !== percentage) {
              percentage = newPercentage
              console.log(`${percentage}%`, `${fileName}`)
            }
          })
          .onError((error) => {
            // setFile(undefined)
            console.error(error)
          })

        props.progress({ fileName: videoFileName, status: 1 })

        uploader.start()
        // end video upload
      }
      const formData = new FormData();
      for (let key in params) {
        formData.append(key, params[key]);
      }
      setloading(true);
      setDisabled(true);

      Api("PUT", "class-info/edit", formData)
        .then((res) => {
          if (res.data.status) {
            if (toaster.current) toaster.current.success(res.data.message);
            setTimeout((res) => {
              props.history.push("/class");
            }, 1000);
            setloading(false);
            setDisabled(false);
          } else {
            if (toaster.current) toaster.current.error(res.data.message);
            setloading(false);
            setDisabled(false);
          }
        })
        .catch((err) => {
          if (toaster.current) toaster.current.error(err.response);
          setloading(false);
          setDisabled(false);
        });
    }
  };

  return (
    <UserWrapper {...props}>
      <div className="pos-relative">
        <PageTitle
          title="sidebar.class"
          className="plr-15"
          breadCrumb={[
            {
              name: "sidebar.class",
            },
            {
              name: hasParam ? "action.edit" : "action.add",
            },
          ]}
        />
        <div
          className="back-icon fs-15 demi-bold-text cursor-pointer"
          onClick={() => props.history.push("/class")}
        >
          <i className="fas fa-step-backward"></i> Back
        </div>
      </div>
      <div className="plr-15">
        <div className="roe-card-style mtb-15">
          <div className="roe-card-header module-header">
            <div className="flex-1 fs-16 demi-bold-text">
              <span className="hash"># </span> {hasParam ? "Edit" : "Add"} Class
            </div>
          </div>
          <div className="roe-card-body">
            <ClassForm
              onSubmit={submitFormHandler}
              editedData={editedData}
              action={hasParam ? "edit" : "add"}
              loading={loading}
              disabled={disabled}
              loader={loader}
            />
          </div>
        </div>
        <Toaster ref={toaster} />
      </div>
    </UserWrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    ...state.themeChanger,
  };
};

export default connect(mapStateToProps, { progress, complete })(ClassEdit);
