import React from "react";
import PageWrapper from "./style";
import PageTitle from "components/common/PageTitle";
import { connect } from "react-redux";
import ClassTable from "../../components/class/ClassTable";
import UploadActions from "../../redux/fileUpload/actions";

const { failed, clear } = UploadActions;

const Class = (props) => {
  return (
    <PageWrapper {...props}>
      <PageTitle title="sidebar.class" className="plr-15" />
      <div className="user-tbl">
        <ClassTable {...props} />
      </div>
    </PageWrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    ...state.themeChanger,
    ...state.fileUpload
  };
};

export default connect(mapStateToProps, { failed, clear })(Class);
