import authAction from "./actions";

const initState = {
  isLogin: false,
  accessToken: null,
  firstName: null,
  lastName: null,
  email: null,
  key: null,
  profile_image: null,
  modules: null,
};

export default function rootReducer(state = initState, action) {
  switch (action.type) {
    case authAction.LOGIN:
      return {
        ...state,
        isLogin: action.isLogin,
        accessToken: action.accessToken,
        firstName: action.firstName,
        lastName: action.lastName,
        email: action.email,
        key: action.key,
        id: action.id,
        profile_image: action.profile_image,
        modules: action.modules,
        userType: action.userType,
      };
    case authAction.LOGOUT:
      return {
        ...state,
        isLogin: action.isLogin,
        accessToken: null,
      };
    case authAction.ADMIN:
      return {
        ...state,
        firstName: action.firstName,
        lastName: action.lastName,
        email: action.email,
        profile_image: action.profile_image,
      };
    default:
      return state;
  }
}
