const authActions = {
  LOGIN: "LOGIN",
  LOGOUT: "LOGOUT",
  ADMIN: "ADMIN",

  login: (data) => {
    return {
      type: authActions.LOGIN,
      isLogin: true,
      accessToken: data.token,
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      id: data.id,
      profile_image: data.profile_image,
      modules: data.modules,
      userType: data.userType,
    };
  },

  logout: () => {
    return {
      type: authActions.LOGOUT,
      isLogin: false,
      accessToken: null,
    };
  },

  admin: (data) => {
    return {
      type: authActions.ADMIN,
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      profile_image: data.profile_image,
    };
  },
};

export default authActions;
