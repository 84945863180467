const authActions = {
  PROGRESS: "PROGRESS",
  COMPLETE: "COMPLETE",
  FAILED: "FAILED",
  CLEAR: "CLEAR",

  progress: (data) => {
    return {
      type: authActions.PROGRESS,
      ...data
    };
  },

  complete: (data) => {
    return {
      type: authActions.COMPLETE,
      ...data
    };
  },

  failed: () => {
    return {
      type: authActions.FAILED,
    };
  },

  clear: () => {
    return {
      type: authActions.CLEAR,
    };
  },
};

export default authActions;
