import React from "react";
import Placeholder from "../../assets/images/Logo.png";

const viewModal = (props) => {
  let data = props.data;
  return (
    <div className="wp-100">
      <div
        className="fs-15 demi-bold-text cursor-pointer float-right"
        onClick={() => props.toggle()}
      >
        <i className="fa fa-times"></i>
      </div>
      <div className=" fs-20 font-weight-bolder">Chef information</div>
      <hr />
      <div className="row">
        <div className="col-12 pt-4">
          <div>
            <div style={{ fontWeight: "bolder", textAlign: "left" }}>
              Profile Image
            </div>
            <img
              src={data.profile_photo ? data.profile_photo : Placeholder}
              alt=""
              height="150px"
              width="150px"
              style={{ borderRadius: "6%" }}
            />
          </div>
        </div>
        <div className="col-6 pt-4">
          <div>
            <div style={{ fontWeight: "bolder", textAlign: "left" }}>Name</div>
            <div className="text-left">{`${data.name}`}</div>
          </div>
        </div>
        <div className="col-6 pt-4">
          <div>
            <div style={{ fontWeight: "bolder", textAlign: "left" }}>Email</div>
            <div className="text-left">{`${data.email ? data.email : "--"}`}</div>
          </div>
        </div>
        <div className="col-6 pt-4">
          <div>
            <div style={{ fontWeight: "bolder", textAlign: "left" }}>
              Currently active
            </div>
            <div className="text-left">{`${
              data.is_active ? "Yes" : "No"
            }`}</div>
          </div>
        </div>
        <div className="col-6 pt-4">
          <div>
            <div style={{ fontWeight: "bolder", textAlign: "left" }}>
              Phone number
            </div>
            <div className="text-left">{`${
              data.phone_number ? data.phone_number : "--"
            }`}</div>
          </div>
        </div>
        {/* <div className="col-6 pt-4">
          <div>
            <div style={{ fontWeight: "bolder", textAlign: "left" }}>
              Number of Classes
            </div>
            <div className="text-left">{`${
              data.class_info_count ? data.class_info_count : "--"
            }`}</div>
          </div>
        </div> */}
        <div className="col-12 pt-4">
          <div>
            <div style={{ fontWeight: "bolder", textAlign: "left" }}>Bio</div>
            <div className="text-left">{`${data.bio ? data.bio : "--"}`}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default viewModal;
