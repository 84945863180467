import React, { useRef, useState } from "react";
import Placeholder from "../../assets/images/Logo.png";
import Button from "components/button/Button";
import Api from "helper/Api";
import Toaster from "../common/Toaster";
import SmallLoader from "../common/SmallLoader";

const viewModal = (props) => {
  let data = props.adminData;
  const [loading, setloading] = useState(false);
  const toaster = useRef();

  const sendMail = (id) => {
    setloading(true);
    Api("POST", `user/resend-activation-code`, { id })
      .then((res) => {
        if (res.data.status) {
          setloading(false);
          console.log("toaster.current", toaster.current);
          if (toaster.current) toaster.current.success(res.data.message);
        }
      })
      .catch((err) => {
        setloading(false);
        if (toaster.current) toaster.current.error(err.response);
      });
  };

  return (
    <div className="wp-100">
      <div
        className="fs-15 demi-bold-text cursor-pointer float-right"
        onClick={() => props.toggle()}
      >
        <i className="fa fa-times"></i>
      </div>
      <div className=" fs-20 font-weight-bolder">User information</div>
      <hr />
      <div className="row">
        <div className="col-12 pt-4">
          <div>
            <div style={{ fontWeight: "bolder", textAlign: "left" }}>
              Profile Image
            </div>
            <img
              src={data.profile_photo ? data.profile_photo : Placeholder}
              alt=""
              height="150px"
              width="150px"
              style={{ borderRadius: "6%" }}
            />
          </div>
        </div>
        <div className="col-6 pt-4">
          <div>
            <div style={{ fontWeight: "bolder", textAlign: "left" }}>First Name</div>
            <div className="text-left">{`${data.first_name}`}</div>
          </div>
        </div>
        <div className="col-6 pt-4">
          <div>
            <div style={{ fontWeight: "bolder", textAlign: "left" }}>Last Name</div>
            <div className="text-left">{`${data.last_name}`}</div>
          </div>
        </div>
        <div className="col-6 pt-4">
          <div>
            <div style={{ fontWeight: "bolder", textAlign: "left" }}>Email</div>
            <div className="text-left">{`${data.email}`}</div>
          </div>
        </div>
        <div className="col-6 pt-4">
          <div>
            <div style={{ fontWeight: "bolder", textAlign: "left" }}>
              Currently active
            </div>
            <div className="text-left">{`${data.is_active ? "Yes" : "No"
              }`}</div>
          </div>
        </div>
        <div className="col-6 pt-4">
          <div style={{ fontWeight: "bolder", textAlign: "left" }}>
            Activation Code
          </div>
          {data.is_active ? (data.alreadyUsedSubscriptionCode ? data.alreadyUsedSubscriptionCode.subscription_code : "-") : (data.checkout_info && data.checkout_info.subscription_code ? data.checkout_info.subscription_code.subscription_code : "-")}

          {!loading && ((data.is_active && data.alreadyUsedSubscriptionCode) || (!data.is_active && data.checkout_info && data.checkout_info.subscription_code)) && (
            <Button
              className="c-btn ma-5"
              onClick={() => sendMail(data.id)}
              style={{
                backgroundColor: "black",
                color: "white",
              }}
            >
              Send Mail
            </Button>
          )}
          {loading && <SmallLoader />}
        </div>
      </div>
      <Toaster ref={toaster} />
    </div>
  );
};

export default viewModal;
