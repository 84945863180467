import { withFormik } from "formik";
import * as Yup from "yup";
import moment from "moment";

const formikEnhancer = withFormik({
  validationSchema: Yup.object().shape({
    name: Yup.string()
      .trim()
      .required("This field is required"),
    // recipe_name_start_time: Yup.date()
    //   .required("This field is required"),
    // recipe_name_end_time: Yup.date()
    //   .required("This field is required").min(Yup.ref('recipe_name_start_time'), "End time must come after start time"),
    // classType: Yup.string()
    //   .trim()
    //   .required("This field is required"),
    // description: Yup.string()
    //   .trim()
    //   .required("This field is required"),
    // instructions: Yup.string()
    //   .trim()
    //   .required("This field is required"),
    // ingredients: Yup.string()
    //   .trim()
    //   .required("This field is required"),
    // ingredient_start_time: Yup.date()
    //   .required("This field is required"),
    // ingredient_end_time: Yup.date()
    //   .required("This field is required").min(Yup.ref('ingredient_start_time'), "End time must come after start time"),
    // chef: Yup.string()
    //   .trim()
    //   .required("This field is required"),
    // mealType: Yup.string()
    //   .trim()
    //   .nullable()
    //   .required("This field is required"),
    // photo: Yup.string()
    //   .nullable()
    //   .required("This field is required"),
    // videoLink: Yup.string()
    //   .trim()
    //   .when("isVideoLink", {
    //     is: true,
    //     then: Yup.string().required("This field is required"),
    //   }),
    // videoName: Yup.string()
    //   .trim()
    //   .when("isVideoLink", {
    //     is: false,
    //     then: Yup.string().required("This field is required"),
    //   }),
    // videoDuration: Yup.string()
    //   .trim()
    //   .required("This field is required"),
    // date: Yup.string()
    //   .trim()
    //   .nullable()
    //   .required("This field is required"),
    // time: Yup.string()
    //   .trim()
    //   .nullable()
    //   .required("This field is required"),
  }),
  mapPropsToValues: (props) => ({
    id:
      props && props.editedData && props.editedData.id
        ? props.editedData.id
        : "",
    name:
      props && props.editedData && props.editedData
        ? props.editedData.name
        : "",
    classType:
      props && props.editedData && props.editedData.class_type
        ? props.editedData.class_type
        : "",
    chef:
      props && props.editedData && props.editedData.chef_id
        ? props.editedData.chef_id
        : "",

    instructions:
      props && props.editedData && props.editedData.class_instructions
        ? props.editedData.class_instructions
        : [],
    ingredients:
      props && props.editedData && props.editedData.class_ingredients
        ? props.editedData.class_ingredients
        : [],
    description:
      props && props.editedData && props.editedData.description
        ? props.editedData.description
        : "",
    isVideoLink:
      props && props.editedData && props.editedData.video_link ? false : false,
    videoName:
      props && props.editedData && props.editedData.video_name
        ? props.editedData.video_name
        : "",
    videoLink:
      props && props.editedData && props.editedData.video_link
        ? props.editedData.video_link
        : "",
    mealType: props && props.editedData && props.editedData.mealType ? props.editedData.mealType : "",
    videoDuration:
      props && props.editedData && props.editedData.duration
        ? props.editedData.duration
        : "",
    videoDurationInFormat:
      props && props.editedData && props.editedData.duration
        ? moment.utc(props.editedData.duration * 1000).format('HH:mm:ss')
        : "",
    date:
      props && props.editedData && props.editedData.class_date
        ? new Date(props.editedData.class_date)
        : "",
    time:
      props && props.editedData && props.editedData.class_time
        ? props.editedData.class_time
        : "",
    photo:
      props && props.editedData && props.editedData.image
        ? props.editedData.image
        : "",
    recipe_name_start_time:
      props && props.editedData && props.editedData.recipe_name_start_time
        ? moment(props.editedData.recipe_name_start_time, 'HH:mm:ss')
        : moment("00:00:01", 'HH:mm:ss'),
    recipe_name_end_time:
      props && props.editedData && props.editedData.recipe_name_end_time
        ? moment(props.editedData.recipe_name_end_time, 'HH:mm:ss')
        : "",
    ingredient_start_time:
      props && props.editedData && props.editedData.ingredient_start_time
        ? moment(props.editedData.ingredient_start_time, 'HH:mm:ss')
        : "",
    ingredient_end_time:
      props && props.editedData && props.editedData.ingredient_end_time
        ? moment(props.editedData.ingredient_end_time, 'HH:mm:ss')
        : "",
  }),
  handleSubmit: (values) => { },
  displayName: "CustomValidationForm",
  enableReinitialize: true,
});

export default formikEnhancer;
