import React from "react";
import PageWrapper from "./style";
import PageTitle from "components/common/PageTitle";
import { connect } from "react-redux";
import ChefTable from "../../components/chef/ChefTable";

const Chef = (props) => {
  return (
    <PageWrapper {...props}>
      <PageTitle title="sidebar.chef" className="plr-15" />
      <div className="user-tbl">
        <ChefTable {...props} />
      </div>
    </PageWrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    ...state.themeChanger,
  };
};

export default connect(mapStateToProps, null)(Chef);
