import React, { useState, useEffect, useRef } from "react";
import { compose } from "redux";
import enhancer from "./Validator";
import SmallLoader from "../common/SmallLoader";
import Loader from "components/common/Loader";
import { ImageResolution, VideoResolution } from "../../helper/constant";
import Placeholder from "../../assets/images/Logo.png";
import { Input } from "reactstrap";
import Api from "helper/Api";
import Toaster from "../common/Toaster";
import { withRouter } from "react-router";
import DatePicker from "react-datepicker";
// import CKEditor from "ckeditor4-react";
// import { config } from "../../util/editorconfig";
// import ReactTagInput from "@pathofdev/react-tag-input";
import ReactDragListView from "react-drag-listview";
import "@pathofdev/react-tag-input/build/index.css";
import { FieldArray } from "formik";
import Button from "components/button/Button";
import { Creatable } from "react-select";
import TimePicker from "rc-time-picker";
import moment from "moment";

// let DeleteTicket = [];

const ChefForm = (props) => {
  const {
    handleChange,
    handleBlur,
    errors,
    touched,
    submitCount,
    onSubmit,
    values,
    action,
    setFieldTouched,
    setFieldValue,
  } = props;

  const toaster = useRef();

  // const getItems = (count) =>
  //   Array.from({ length: count }, (v, k) => k).map((k) => ({
  //     id: `item-${k}`,
  //     content: `item ${k}`,
  //   }));

  const [uploadImage, setUploadImage] = useState(null);
  const [chefList, setChefList] = useState([]);
  const [mealList, setMealList] = useState([]);
  const [videoSrc, setVideoSrc] = useState("");
  const [videoError, setVideoError] = useState("");
  const [position, setposition] = useState(1);
  const [ingredientsList, setingredientsList] = useState([]);

  useEffect(() => {
    setFieldValue("isVideoLink", true);

    Api("GET", `chef/list-chef?search=&sort_by=&order=`).then((response) => {
      if (response.data.status) {
        if (response.data.data.length) {
          setChefList(response.data.data);
        } else {
          if (toaster.current)
            toaster.current.error("First create chef for create the class");
          setTimeout((res) => {
            props.history.push("/chef/add");
          }, 2000);
        }
      }
    });
    Api("GET", `meal-icon/list-meal-icon?search=&sort_by=&order=`).then(
      (response) => {
        if (response.data.data.length) {
          setMealList(response.data.data);
        } else {
          if (toaster.current) toaster.current.error("No meal for selection");
          setTimeout((res) => {
            props.history.push("/meal");
          }, 2000);
        }
      }
    );
    Api("GET", `ingredients/all`)
      .then((response) => {
        const ingredientList =
          response.data.data.length > 0
            ? response.data.data.map((res) => {
              return {
                label: res.name,
                value: res.name,
                id: res.id,
              };
            })
            : [];
        return ingredientList;
      })
      .then((ingredientsList) => {
        setingredientsList(ingredientsList);
      });
  }, []);

  useEffect(() => {
    if (props.editedData) {
      const newDate = new Date(`${props.editedData.class_date} utc`);

      if (props.editedData.class_time && props.editedData.class_date) {

        const time = props.editedData.class_time.split(":");
        const date = props.editedData.class_date.split("-");

        newDate.setUTCFullYear(date[0]);
        newDate.setUTCMonth(date[1] - 1);
        newDate.setUTCDate(date[2]);
        newDate.setUTCHours(time[0]);
        newDate.setUTCMinutes(time[1]);
        newDate.setUTCSeconds(time[2]);
        setFieldValue("time", newDate);
        setFieldValue("date", newDate);


      }
    }

    // ingredients
    if (props.editedData && props.editedData.class_ingredients) {
      const Data =
        props.editedData &&
        props.editedData.class_ingredients &&
        props.editedData.class_ingredients.map((res) => {
          return {
            food: res.ingredient.name,
            ...res,
          };
        });
      setFieldValue("ingredients", Data);
    }
  }, [props.editedData]);

  const Error = (props) => {
    const field1 = props.field;
    if ((errors[field1] && touched[field1]) || submitCount > 0) {
      return (
        <span className={props.class ? props.class : "error-msg"}>
          {errors[field1]}
        </span>
      );
    } else {
      return <span />;
    }
  };

  const handleSubmit = () => {
    let { values, isValid, handleSubmit } = props;

    let test = 0;
    if (isValid) {
      if ((values.ingredient_end_time && moment.duration(values.ingredient_end_time.format("HH:mm:ss")).asSeconds() > values.videoDuration)
        || (values.recipe_name_end_time && moment.duration(values.recipe_name_end_time.format("HH:mm:ss")).asSeconds() > values.videoDuration)) {
        test = 1;
        if (toaster.current) toaster.current.error("End time must be lower then video duration time.");
      }

      for (let i = 0; i < values.instructions.length; i++) {
        if (moment.duration(values.instructions[i].end_timestamp).asSeconds() > values.videoDuration
          || moment.duration(values.instructions[i].timestamp).asSeconds() > values.videoDuration) {
          if (toaster.current) {
            test = 1;
            toaster.current.error("End time must be lower then video duration time.");
            break;
          }
        }
      }

      if (test == 0) {
        onSubmit(values, action);
      }
    }
    handleSubmit();
  };

  const handleImage = (e, setValue) => {
    if (e.target.files[0]) {
      let fileObj = e.target.files[0];

      if (fileObj) {
        const img = new Image();

        img.src = window.URL.createObjectURL(fileObj);

        img.onload = function () {
          const width = img.naturalWidth,
            height = img.naturalHeight;

          window.URL.revokeObjectURL(img.src);
          setValue("width", width);
          setValue("height", height);
          setFieldTouched("photo", true, true);
          if (
            width >= ImageResolution.WIDTH &&
            height >= ImageResolution.HEIGHT
          ) {
            setValue("photo", fileObj);
            setUploadImage(URL.createObjectURL(fileObj));
          }
        };
      } else {
        //No file was input or browser doesn't support client side reading
        // form.submit();
      }
    } else {
      setUploadImage(null);
    }
  };

  const handleVideo = (e, setValue) => {
    setVideoError("");
    if (e.target.files[0]) {
      let fileObj = e.target.files[0];

      if (fileObj) {
        setFieldValue("videoName", fileObj);
        var src = URL.createObjectURL(fileObj);
        setVideoSrc(src);
      } else {
        //No file was input or browser doesn't support client side reading
        // form.submit();
      }
    }
  };

  const getListStyle = (isDraggingOver) => ({
    // background: isDraggingOver ? "lightgrey" : "lightgrey",
    // border: "1px solid lightgrey",
    // width: 250,
  });

  const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    border: "1px solid lightgrey",
    userSelect: "none",
    margin: `0 0 ${8}px 0`,

    // change background colour if dragging
    // background: isDragging ? "lightgreen" : "grey",

    // styles we need to apply on draggables
    ...draggableStyle,
  });

  // const getItemStyle2 = (isDragging, draggableStyle) => ({
  //   // some basic styles to make the items look a bit nicer
  //   userSelect: "none",
  //   margin: `0 0 ${8}px 0`,

  //   // change background colour if dragging
  //   // background: isDragging ? "lightgreen" : "grey",

  //   // styles we need to apply on draggables
  //   ...draggableStyle,
  // });

  function onDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const item = reorder(
      values.instructions,
      result.source.index,
      result.destination.index
    );

    setFieldValue("instructions", item);
  }

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const dragPropsInstructions = {
    onDragEnd(fromIndex, toIndex) {
      const item = reorder(values.instructions, fromIndex, toIndex);
      setFieldValue("instructions", item);
    },
    nodeSelector: "div",
    handleSelector: "i",
  };

  const dragPropsIngredients = {
    onDragEnd(fromIndex, toIndex) {
      const item = reorder(values.ingredients, fromIndex, toIndex);
      setFieldValue("ingredients", item);
    },
    nodeSelector: "div",
    handleSelector: "i",
  };

  const videoEl = useRef(null);

  const handleLoadedMetadata = () => {
    const video = videoEl.current;
    if (!video) return;
    if (video.videoWidth != VideoResolution.WIDTH || video.videoHeight != VideoResolution.HEIGHT) {
      setVideoSrc("");
      setFieldValue("videoName", {});
    }
    setFieldValue("videoDuration", video.duration);
    setFieldValue("videoDurationInFormat", moment.utc(video.duration * 1000).format('HH:mm:ss'));
  };

  return (
    <div>
      {!props.loader ? (
        <form>
          <div className="form-group">
            <label className="fs-16 medium-text">Name</label>
            <input
              type="text"
              className="form-control react-form-input"
              id="name"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.name}
              placeholder="Name"
            />
            <Error field="name" />
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label className="fs-16 medium-text">Recipe Start Time</label>
                <br />
                <TimePicker
                  className="react-form-input"
                  value={values.recipe_name_start_time ? moment(values.recipe_name_start_time, 'HH:mm:ss') : null}
                  onChange={(e) => {
                    setFieldValue("recipe_name_start_time", e);
                    setFieldTouched("recipe_name_start_time", true);
                  }}
                  onBlur={handleBlur}
                  timeCaption="Time"
                  dateFormat="HH:mm:ss"
                  onClose={handleChange}
                />
                <Error field="recipe_name_start_time" />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label className="fs-16 medium-text">Recipe End Time</label>
                <br />
                <TimePicker
                  className=" react-form-input"
                  value={values.recipe_name_end_time ? moment(values.recipe_name_end_time, 'HH:mm:ss') : null}
                  onChange={(e) => {
                    setFieldValue("recipe_name_end_time", e);
                    setFieldTouched("recipe_name_end_time", true);
                  }}
                  timeCaption="Time"
                  dateFormat="HH:mm:ss"
                />
                <Error field="recipe_name_end_time" />
              </div>
            </div>
          </div>
          <div className="form-group">
            <label className="fs-16 medium-text">Class type</label>
            <Input
              type="select"
              name="classType"
              id="classType"
              value={values.classType}
              onChange={handleChange}
              onBlur={handleBlur}
            >
              <option>Select class type</option>
              <option value="live">Live</option>;
              <option value="on-demand">On-demand</option>;
            </Input>
            <Error field="classType" />
          </div>
          <div className="form-group">
            <label className="fs-16 medium-text">Select chef</label>
            <Input
              type="select"
              name="chef"
              id="chef"
              value={values.chef}
              onChange={handleChange}
              onBlur={handleBlur}
            >
              <option value="">Select chef</option>
              {chefList.length &&
                chefList.map((result) => {
                  return <option value={result.id}>{result.name}</option>;
                })}
            </Input>
            <Error field="chef" />
          </div>
          <div className="form-group">
            <label className="fs-16 medium-text">
              Select meal type (You can select multiple with cmd+select)
            </label>
            <Input
              type="select"
              name="selectMulti"
              id="mealType"
              value={values.mealType}
              onChange={(event) => {
                let opts = [],
                  opt;
                for (
                  let i = 0, len = event.target.options.length;
                  i < len;
                  i++
                ) {
                  opt = event.target.options[i];
                  if (opt.selected) {
                    opts.push(opt.value);
                  }
                }
                setFieldValue("mealType", opts);
              }}
              onBlur={handleBlur}
              multiple
            >
              {mealList.length &&
                mealList.map((result) => {
                  return <option value={result.id}>{result.meal_type}</option>;
                })}
            </Input>
            <Error field="mealType" />
          </div>
          <div className="form-group">
            <label className="fs-16 medium-text">Description</label>
            <textarea
              type="textarea"
              className="form-control react-form-input"
              id="description"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.description}
              placeholder="Description"
            />
            <Error field="description" />
          </div>
          <div className="form-group">
            <label className="fs-16 medium-text">Instructions</label>
            {/* field array instructions */}
            <FieldArray
              name="instructions"
              render={({ insert, remove, push }) => (
                <>
                  {values.instructions.length > 0 && (
                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: "60px auto 150px 150px 30px 100px",
                        columnGap: "10px",
                        padding: "5px",
                        margin: "10px 0",
                      }}
                    >
                      <div>Sr. No.</div>
                      <div>Instruction</div>
                      <div>Start Time</div>
                      <div>End Time</div>
                    </div>
                  )}
                  <ReactDragListView {...dragPropsInstructions}>
                    {values &&
                      values.instructions.length > 0 &&
                      values.instructions.map((friend, index) => (
                        <>
                          <div
                            style={{
                              display: "grid",
                              gridTemplateColumns: "60px auto 150px 150px 30px 100px",
                              alignItems: "center",
                              padding: "5px",
                              margin: "10px 0",
                              columnGap: "10px",
                            }}
                          >
                            <div>{index + 1}.</div>
                            <div>
                              <input
                                type="text"
                                className="form-control react-form-input"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                name={`instructions.${index}.text`}
                                value={friend.text}
                                maxLength="124"
                              />
                            </div>
                            <div>
                              <TimePicker
                                className=" react-form-input"
                                value={friend.timestamp ? moment(friend.timestamp, 'HH:mm:ss') : ""}
                                onChange={(e) => {
                                  setFieldValue(`instructions.${index}.timestamp`, e.format('HH:mm:ss'));
                                  setFieldTouched(`instructions.${index}.timestamp`, true);
                                }}
                                timeCaption="Time"
                                dateFormat="HH:mm:ss"
                                name={`instructions.${index}.timestamp`}
                              />
                            </div>
                            <div>
                              <TimePicker
                                className="react-form-input"
                                value={friend.end_timestamp ? moment(friend.end_timestamp, 'HH:mm:ss') : ""}
                                onChange={(e) => {
                                  setFieldValue(`instructions.${index}.end_timestamp`, e.format('HH:mm:ss'));
                                  setFieldTouched(`instructions.${index}.end_timestamp`, true);
                                }}
                                timeCaption="Time"
                                dateFormat="HH:mm:ss"
                                name={`instructions.${index}.end_timestamp`}
                              />
                            </div>
                            <i
                              class="fa fa-bars"
                              aria-hidden="true"
                              style={{ fontSize: "30px" }}
                            ></i>
                            <Button
                              className="c-btn c-danger form-button fs-16 demi-bold-text mr-15"
                              onClick={() => {
                                remove(index);
                              }}
                            >
                              Remove
                            </Button>
                          </div>
                        </>
                      ))}
                  </ReactDragListView>
                  <div className="pt-2">
                    <Button
                      className="c-btn c-success form-button fs-16 demi-bold-text mr-15"
                      type="button"
                      onClick={() => {
                        push({
                          text: "",
                          timestamp: "",
                        });
                      }}
                      style={{ maxWidth: "163px" }}
                    >
                      Add Instructions
                    </Button>
                    <Button
                      className="c-btn c-danger form-button fs-16 demi-bold-text mr-15"
                      type="button"
                      onClick={(event) => {
                        setFieldValue("instructions", []);
                      }}
                      style={{ maxWidth: "125px" }}
                    >
                      Reset
                    </Button>
                  </div>
                </>
              )}
            />
            {/* </DragDropContext> */}
            <Error field="instructions" />
          </div>
          {/* field array ingredients */}
          <div className="form-group">
            <label className="fs-16 medium-text">Ingredients</label>
            <div className="form-group">
              {/* <DragDropContext onDragEnd={onDragEnd}> */}
              <FieldArray
                name="ingredients"
                render={({ insert, remove, push }) => (
                  <>
                    {values.ingredients.length > 0 && (
                      <div
                        style={{
                          display: "grid",
                          gridTemplateColumns: "auto auto 30px auto",
                        }}
                      >
                        <div>Measurement</div>
                        <div>Food</div>
                      </div>
                    )}
                    <ReactDragListView {...dragPropsIngredients}>
                      {values &&
                        values.ingredients.length > 0 &&
                        values.ingredients.map((friend, index) => (
                          <>
                            <div
                              style={{
                                display: "grid",
                                gridTemplateColumns: "auto 350px 30px auto",
                                padding: "5px",
                                margin: "10px 0",
                                columnGap: "10px",
                              }}
                            >
                              <div>
                                <input
                                  type="text"
                                  className="form-control react-form-input"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  name={`ingredients.${index}.measurement`}
                                  value={friend.measurement}
                                />
                              </div>
                              <div>
                                <Creatable
                                  isClearable
                                  formatCreateLabel={(userInput) =>
                                    `Ingredient does not exist. Add to Ingredients Management section.`
                                  }
                                  onChange={(data) => {
                                    const newdata = data
                                      ? data.value.charAt(0).toUpperCase() +
                                      data.value.slice(1)
                                      : "";
                                    handleChange();
                                    const Data = values.ingredients;
                                    Data[index].ingredient_id = data
                                      ? data.id
                                      : "";
                                    Data[index].food = newdata ? newdata : "";
                                    setFieldValue("ingredients", [...Data]);
                                  }}
                                  onCreateOption={(data) => {
                                    const newdata = data
                                      ? data.charAt(0).toUpperCase() +
                                      data.slice(1)
                                      : "";
                                    const ingredients = ingredientsList;
                                    ingredients.push({
                                      label: newdata,
                                      value: newdata,
                                    });
                                    setingredientsList([...ingredients]);
                                    // if (toaster.current)
                                    //   toaster.current.error(
                                    //     "Ingredient does not exist. Add to Ingredients Management section."
                                    //   );

                                    const Data = values.ingredients;
                                    Data[index].food = newdata ? newdata : "";
                                    Data[index].name = newdata ? newdata : "";
                                    setFieldValue("ingredients", [...Data]);
                                  }}
                                  value={{
                                    label: friend.food,
                                    value: friend.food,
                                  }}
                                  options={ingredientsList}
                                />
                                {/* <input
                                type="text"
                                className="form-control react-form-input"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                name={`ingredients.${index}.food`}
                                value={friend.food}
                              /> */}
                              </div>
                              <i
                                class="fa fa-bars"
                                aria-hidden="true"
                                style={{ fontSize: "30px" }}
                              ></i>
                              <Button
                                className="c-btn c-danger form-button fs-16 demi-bold-text mr-15"
                                onClick={() => {
                                  remove(index);
                                }}
                              >
                                Remove
                              </Button>
                            </div>
                          </>
                        ))}
                    </ReactDragListView>
                    <div className="pt-2">
                      <Button
                        className="c-btn c-success form-button fs-16 demi-bold-text mr-15"
                        type="button"
                        onClick={() => {
                          push({
                            measurement: "",
                            food: "",
                          });
                        }}
                        style={{ maxWidth: "163px" }}
                      >
                        Add Ingredients
                      </Button>
                      <Button
                        className="c-btn c-danger form-button fs-16 demi-bold-text mr-15"
                        type="button"
                        onClick={(event) => {
                          setFieldValue("ingredients", []);
                        }}
                        style={{ maxWidth: "125px" }}
                      >
                        Reset
                      </Button>
                    </div>
                  </>
                )}
              />
              {/* </DragDropContext> */}
              <Error field="ingredients" />
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label className="fs-16 medium-text">Ingredients Start Time</label>
                <br />
                <TimePicker
                  className=" react-form-input"
                  value={values.ingredient_start_time ? moment(values.ingredient_start_time, 'HH:mm:ss') : null}
                  onChange={(e) => {
                    setFieldValue("ingredient_start_time", e);
                    setFieldTouched("ingredient_start_time", true);
                  }}
                  timeCaption="Time"
                  dateFormat="HH:mm:ss"
                />
                <Error field="ingredient_start_time" />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label className="fs-16 medium-text">Ingredients End Time</label>
                <br />
                <TimePicker
                  className="react-form-input"
                  onChange={(e) => {
                    setFieldValue("ingredient_end_time", e);
                    setFieldTouched("ingredient_end_time", true);
                  }}
                  value={values.ingredient_end_time ? moment(values.ingredient_end_time, 'HH:mm:ss') : null}
                  timeCaption="Time"
                  dateFormat="HH:mm:ss"
                />
                <Error field="ingredient_end_time" />
              </div>
            </div>
          </div>
          {/* <div className="form-group">
            <label className="fs-16 medium-text">Select type</label>
            <div>
              <input
                type="radio"
                value={values.isVideoLink}
                checked={values.isVideoLink}
                onChange={(e) => setFieldValue("isVideoLink", true)}
                style={{ marginRight: "10px" }}
              />
              Link
              <input
                type="radio"
                value={values.isVideoLink}
                checked={values.isVideoLink}
                onChange={(e) => setFieldValue("isVideoLink", false)}
                style={{ marginRight: "10px", marginLeft: "10px" }}
              />
              Upload video
            </div>
          </div> */}
          {/* {values.isVideoLink && (
            <div className="form-group">
              <div>
                <label className="fs-16 medium-text">Video link</label>
                <input
                  type="text"
                  className="form-control react-form-input"
                  id="videoLink"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.videoLink}
                  placeholder="Video link"
                />
                <Error field="videoLink" />
              </div>
            </div>
          )} */}
          {/* {!values.isVideoLink && ( */}
          <div>
            <div className="form-group">
              <label className="fs-16 medium-text">Video</label>
              <br />
              <div className="file-upload">
                <label className="c-btn c-primary form-button fs-16 demi-bold-text mt-15">
                  Upload Video
                  <input
                    id="videoName"
                    className="file-upload__input"
                    name="file-upload"
                    type="file"
                    accept="video/*"
                    onChange={(e) => handleVideo(e)}
                    onBlur={handleBlur}
                  />
                </label>
              </div>
            </div>

            <Error field="videoName" />
            <div>{videoError}</div>
            <div>
              {values.videoName && !videoError && (
                <video
                  src={videoSrc ? videoSrc : values.videoName}
                  controls="controls"
                  style={{
                    width: "200px",
                    borderRadius: "4%",
                    height: "150px",
                    background: "#404040",
                  }}
                  onLoadedMetadata={handleLoadedMetadata}
                  onError={() => setVideoError("You need to upload 960*360 resolution video only.")}
                  ref={videoEl}
                ></video>
              )}
            </div>
          </div>
          {/* )} */}
          <div className="form-group">
            <label className="fs-16 medium-text">Video duration</label>
            <input
              type="text"
              className="form-control react-form-input"
              id="videoDurationInFormat"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.videoDurationInFormat}
              placeholder="Video duration in HH:mm:ss"
              min={1}
              disabled={true}
            />
            <Error field="videoDurationInFormat" />
          </div>

          {values.classType !== "on-demand" && (<>
            <div className="form-group">
              <label className="fs-16 medium-text">Select date </label>
              <div>
                <DatePicker
                  selected={values.date}
                  onChange={(data) => setFieldValue("date", data)}
                  minDate={new Date()}
                  className="date"
                // dateFormat="yyyy/MM/dd"
                />
              </div>

              <Error field="date" />
            </div>

            <div className="form-group">
              <label className="fs-16 medium-text">Class Time</label>
              <div>
                <DatePicker
                  selected={values.time}
                  onChange={(time) => {
                    setFieldValue("time", time);
                  }}
                  className="date"
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={10}
                  timeCaption="Time"
                  dateFormat="h:mm aa"
                />
              </div>
              <Error field="time" />
            </div>
          </>)}


          <div>
            <div className="form-group">
              <label className="fs-16 medium-text">Image</label>
              <br />
              <div className="file-upload">
                <label className="c-btn c-primary form-button fs-16 demi-bold-text mt-15">
                  Upload image
                  <input
                    id="photo"
                    className="file-upload__input"
                    name="file-upload"
                    type="file"
                    accept="image/jpg,image/png,image/jpeg"
                    onChange={(e) => handleImage(e, setFieldValue)}
                    onBlur={handleBlur}
                  />
                </label>
              </div>
              <br />
              {
                <img
                  src={
                    uploadImage
                      ? uploadImage
                      : values.photo
                        ? values.photo
                        : Placeholder
                  }
                  alt=""
                  className="mtb-15"
                  style={{
                    width: "200px",
                    borderRadius: "4%",
                    height: "150px",
                    background: "#404040",
                  }}
                />
              }
              <br />
              <Error field="photo" />
            </div>
          </div>
          <div>
            <div>
              {!props.loading && (
                <button
                  type="button"
                  className="c-btn c-info form-button fs-16 demi-bold-text"
                  style={{ maxWidth: "125px" }}
                  onClick={() => handleSubmit()}
                  disabled={props.disabled}
                >
                  Submit
                </button>
              )}
              {props.loading && <SmallLoader />}
            </div>
          </div>
        </form>
      ) : (
        <Loader loading={props.loader} />
      )}
      <Toaster ref={toaster} />
    </div>
  );
};

export default withRouter(compose(enhancer)(ChefForm));
