import React, { useRef, useState } from "react";
import { ForgotIcon } from "helper/constant";
import Api from "helper/Api";
import Toaster from "../../../components/common/Toaster";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import enhancer from "./enhancer/SetPasswordValidator";
import AuthActions from "redux/auth/actions";
import Button from "../../../components/button/Button";

const { logout } = AuthActions;
const SetPassword = (props) => {
  const loginContainer = {
    backgroundPosition: "center center",
    backgroundSize: "cover",
    position: "fixed",
    overflow: "auto",
    top: 0,
    bottom: 0,
    backgroundColor: "#1C1C1C",
  };
  const [loading, setloading] = useState(false);

  const iv = props.match.params.iv;
  const data = props.match.params.data;

  const {
    errors,
    touched,
    submitCount,
    isValid,
    handleSubmit,
    values,
    handleChange,
    handleBlur,
  } = props;

  const toaster = useRef();

  const Error = (props) => {
    const field1 = props.field;
    if ((errors[field1] && touched[field1]) || submitCount > 0) {
      return (
        <span className={props.class ? props.class : "error-msg"}>
          {errors[field1]}
        </span>
      );
    } else {
      return <span />;
    }
  };

  function submitHandler(e) {
    e.preventDefault();
    if (isValid) {
      const params = {
        new_password: values.newPassword,
        iv: iv,
        data: data,
      };
      setloading(true);
      Api("POST", "sub-admin/set-password", params)
        .then((res) => {
          if (res.data.status) {
            if (toaster.current) toaster.current.success(res.data.message);
            props.logout();
            setloading(false);
            props.history.push("/login");
          } else {
            if (toaster.current) toaster.current.error(res.data.message);
          }
        })
        .catch((err) => {
          if (toaster.current) toaster.current.error(err.response);
        });
    }
    handleSubmit();
  }

  return (
    <div>
      <div className="container-fluid" style={loginContainer}>
        <div className="form-container">
          <div className="login-icon">
            <img src={ForgotIcon} alt="icon" height="100px" />
          </div>
          <div className="login-title">Set Password</div>

          <div className="text-center form-info-text plr-24 mt-16">
            Enter Your new password
          </div>

          <form className="pa-24" onSubmit={submitHandler}>
            <div className="form-group">
              <input
                id="newPassword"
                type="password"
                onPaste={(e) => e.preventDefault()}
                className="form-control react-form-input"
                aria-describedby="emailHelp"
                placeholder="Enter Password"
                value={values.newPassword}
                onBlur={handleBlur}
                onChange={handleChange}
              />
              <Error field="newPassword" />
            </div>
            <div className="form-group">
              <input
                id="confirmPassword"
                type="password"
                onPaste={(e) => e.preventDefault()}
                value={values.confirmPassword}
                className="form-control react-form-input"
                aria-describedby="emailHelp"
                placeholder="Confirm Password"
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <Error field="confirmPassword" />
            </div>
            <Button
              type="submit"
              loading={loading}
              className="btn form-button"
              dataStyle="expand-right"
              style={{ position: "relative" }}
            >
              Set password
            </Button>
          </form>
        </div>
      </div>
      <Toaster ref={toaster} />
    </div>
  );
};

export default compose(
  withRouter,
  enhancer,
  connect(null, { logout })
)(SetPassword);
