import React, { useState, useRef } from "react";
import { ForgotIcon } from "helper/constant";
import Toaster from "../../../components/common/Toaster";
import { withRouter } from "react-router-dom";
import ResetPassword from "./ResetPassword";
import Button from "../../../components/button/Button";
import Api from "../../../helper/Api";
const Otp = (props) => {
  const loginContainer = {
    backgroundPosition: "center center",
    backgroundSize: "cover",
    position: "fixed",
    overflow: "auto",
    top: 0,
    bottom: 0,
    backgroundColor: "#1C1C1C",
  };

  const toaster = useRef();
  const [otp, setOtp] = useState();
  const [view, setView] = useState(true);
  const [loading, setloading] = useState(false);

  function submitHandler(e) {
    e.preventDefault();
    const params = {
      otp: otp,
      email: props && props.email,
      otp_token: props && props.otp_token,
    };

    setloading(true);
    Api("POST", "auth/verify-otp", params)
      .then((res) => {
        if (res.data.status) {
          if (toaster.current) toaster.current.success(res.data.message);
          setTimeout(() => {
            setView(false);
            setloading(false);
          }, 1000);
        } else {
          if (toaster.current) toaster.current.error(res.data.message);
          setloading(false);
        }
      })
      .catch((err) => {
        if (toaster.current) toaster.current.error(err.response);
        setloading(false);
      });
  }

  return view ? (
    <div className="container-fluid" style={loginContainer}>
      <div className="form-container">
        <div className="login-icon">
          <img src={ForgotIcon} alt="icon" height="100px" />
        </div>
        <div className="login-title">Enter OTP</div>
        <div className="text-center form-info-text plr-24 mt-16">
          Otp has been provided to your email id
        </div>
        <form className="pa-24" onSubmit={(e) => submitHandler(e)}>
          <div className="form-group">
            <input
              type="text"
              required
              onChange={(e) => {
                setOtp(e.target.value);
              }}
              className="form-control react-form-input"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              placeholder="Enter OTP"
            />
          </div>
          <Button
            type="submit"
            loading={loading}
            className="btn form-button"
            dataStyle="expand-right"
            style={{ position: "relative" }}
          >
            Verify OTP
          </Button>
        </form>
      </div>
      <Toaster ref={toaster} />
    </div>
  ) : (
    <ResetPassword email={props.email} otp_token={props.otp_token} otp={otp} />
  );
};

export default withRouter(Otp);
