import { withFormik } from "formik";
import * as Yup from "yup";

const formikEnhancer = withFormik({
  validationSchema: Yup.object().shape({
    firstName: Yup.string()
      .trim()
      .required("This field is required"),
    lastName: Yup.string()
      .trim()
      .required("This field is required"),
    email: Yup.string()
      .email()
      .trim()
      .required("This field is required"),
    // profilePhoto: Yup.string()
    //   .nullable()
    //   .required("This field is required"),
    roleId: Yup.string().required("This field is required"),
  }),
  mapPropsToValues: (props) => ({
    id:
      props && props.editedData && props.editedData.id
        ? props.editedData.id
        : "",
    firstName:
      props && props.editedData && props.editedData.first_name
        ? props.editedData.first_name
        : "",
    lastName:
      props && props.editedData && props.editedData.last_name
        ? props.editedData.last_name
        : "",
    email:
      props && props.editedData && props.editedData.email
        ? props.editedData.email
        : "",
    profilePhoto:
      props && props.editedData && props.editedData.profile_image
        ? props.editedData.profile_image
        : "",
    roleId:
      props &&
      props.editedData &&
      props.editedData.admin_role &&
      props.editedData.admin_role.id
        ? props.editedData.admin_role.id
        : "",
  }),
  handleSubmit: (values) => {},
  displayName: "CustomValidationForm",
  enableReinitialize: true,
});

export default formikEnhancer;
