import { withFormik } from "formik";
import * as Yup from "yup";

const formikEnhancer = withFormik({
  validationSchema: Yup.object().shape({
    name: Yup.string()
      .trim()
      .required("This field is required"),
  }),
  mapPropsToValues: (props) => ({
    id:
      props && props.editedData && props.editedData.id
        ? props.editedData.id
        : "",
    name:
      props && props.editedData && props.editedData
        ? props.editedData.name
        : "",
  }),
  handleSubmit: (values) => {},
  displayName: "CustomValidationForm",
  enableReinitialize: true,
});

export default formikEnhancer;
