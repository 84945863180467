import Editprofile from "views/Editprofile/Editprofile";
import ChangePassword from "views/ChangePassword/ChangePassword";
import StaticPage from "views/StaticPageManagement/StaticPage";
import StaticPageEdit from "views/StaticPageManagement/StaticPageEdit";
import Dashboard from "../views/Dashboard/Dashboard";
import Admin from "../views/AdminManagement/Admin";
import AdminEdit from "../views/AdminManagement/AdminEdit";
import User from "../views/UserManagement/User";
import Chef from "../views/ChefManagement/Chef";
import ChefEdit from "../views/ChefManagement/ChefEdit";
import Meal from "../views/MealManagement/Meal";
import MealEdit from "../views/MealManagement/MealEdit";
import Class from "../views/ClassManagement/Class";
import ClassEdit from "../views/ClassManagement/ClassEdit";
import UserEdit from "../views/UserManagement/UserEdit";
import Subscription from "../views/SubscriptionManagement/Subscription";
import SubscriptionEdit from "../views/SubscriptionManagement/SubscriptionEdit";
import SubscribedUsers from "../views/SubscribedUsers/SubscribedUsers";
import Ingredients from "../views/IngredientsManagement/Ingredients";
import IngredientsEdit from "../views/IngredientsManagement/IngredientsEdit";
import BlokPrice from "../views/BlokPriceManagement/BlokPrice";
import BlokPriceEdit from "../views/BlokPriceManagement/BlokPriceEdit";

const dashboardRoutes = [
  // Basic routes
  { path: "/dashboard", component: Dashboard },
  { path: "/edit-profile", component: Editprofile },
  { path: "/change-password", component: ChangePassword },
  // Static page routes
  { path: "/static-page", component: StaticPage },
  { path: "/static-page/add", component: StaticPageEdit },
  { path: "/static-page/edit/:id", component: StaticPageEdit },
  // Admin management routes
  { path: "/admin", component: Admin },
  { path: "/admin/add", component: AdminEdit },
  { path: "/admin/edit/:id", component: AdminEdit },
  // User management routes
  { path: "/user", component: User },
  { path: "/user/add", component: UserEdit },
  { path: "/user/edit/:id", component: UserEdit },
  // Chef management routes
  { path: "/chef", component: Chef },
  { path: "/chef/add", component: ChefEdit },
  { path: "/chef/edit/:id", component: ChefEdit },
  // Meal management routes
  { path: "/meal", component: Meal },
  { path: "/meal/add", component: MealEdit },
  { path: "/meal/edit/:id", component: MealEdit },
  // Class management routes
  { path: "/class", component: Class },
  { path: "/class/add", component: ClassEdit },
  { path: "/class/edit/:id", component: ClassEdit },
  //ingredients management routes
  { path: "/ingredients", component: Ingredients },
  { path: "/ingredients/add", component: IngredientsEdit },
  { path: "/ingredients/edit/:id", component: IngredientsEdit },
  // Subscription management routes
  { path: "/subscription", component: Subscription },
  { path: "/subscription/add", component: SubscriptionEdit },
  { path: "/subscription/edit/:id", component: SubscriptionEdit },
  { path: "/subscription-details", component: SubscribedUsers },
  // Blok Price management routes
  { path: "/blok-price", component: BlokPrice },
  { path: "/blok-price/add", component: BlokPriceEdit },
  { path: "/blok-price/edit/:id", component: BlokPriceEdit },
];

export default dashboardRoutes;
