import React, { useState, useEffect, useRef } from "react";
import ReactTable from "react-table";
import Button from "components/button/Button";
import Pagination from "components/common/Pagination";
import "react-table/react-table.css";
import { withRouter } from "react-router";
import Loader from "../common/Loader";
import Moment from "react-moment";
import Toaster from "../common/Toaster";
import { TABLE } from "../../helper/constant";
import Api from "helper/Api";
import CommonModel from "../common/CommonModel";
import View from "./ViewModal";
import Delete from "./DeleteModal";
import Placeholder from "../../assets/images/Logo.png";
import RoyTooltip from "components/common/RoyTooltip";

const ChefTable = (props) => {
  const columns = [
    {
      Header: "Image",
      accessor: "profile_photo",
      className: "text-center",
      sortable: false,
      filterable: false,
      headerClassName: "react-table-header-class",
      Cell: (props) => (
        <div>
          <img
            src={
              props.original.profile_photo
                ? props.original.profile_photo
                : Placeholder
            }
            alt="error"
            style={{ height: "30px", width: "30px", borderRadius: "50%" }}
          />
        </div>
      ),
    },
    {
      Header: "Name",
      accessor: "name",
      className: "text-center",
      filterable: false,
      headerClassName: "react-table-header-class",
    },
    {
      Header: "Email",
      accessor: "email",
      className: "text-center",
      filterable: false,
      headerClassName: "react-table-header-class",
      width: 300,
    },
    {
      Header: "Date",
      accessor: "created_at",
      className: "text-center",
      filterable: false,
      headerClassName: "react-table-header-class",
      Cell: (props) => (
        <div>
          <Moment format="MMM-DD-YYYY">{props.original.created_at}</Moment>
        </div>
      ),
    },
    {
      Header: "Status",
      accessor: "status",
      className: "text-center",
      headerClassName: "react-table-header-class",
      sortable: false,
      filterable: false,
      Cell: (props) => (
        <div className="react-action-class">
          {props.original.is_active ? (
            <div id={`active-${props.original.id}`}>
              <RoyTooltip
                id={`act-${props.original.id}`}
                title={"Click to Deactivate"}
                placement="left"
              >
                <Button
                  className="c-btn c-success mr-10"
                  style={{ minWidth: "50px" }}
                  onClick={() => activeInactiveStatusHandler(props.original)}
                  id={`act-${props.original.id}`}
                >
                  <div className="fs-14  ">
                    <i class="fa fa-check" aria-hidden="true"></i>
                  </div>
                </Button>
              </RoyTooltip>
            </div>
          ) : (
            <div id={`deactive-${props.original.id}`}>
              <RoyTooltip
                id={`dact-${props.original.id}`}
                title={"Click to Activate"}
                placement="left"
              >
                <Button
                  style={{ minWidth: "50px" }}
                  className="c-btn c-danger mr-10"
                  onClick={() => activeInactiveStatusHandler(props.original)}
                  id={`dact-${props.original.id}`}
                >
                  <div>
                    <div className="fs-14" id="hi">
                      <i class="fa fa-times" aria-hidden="true"></i>
                    </div>
                  </div>
                </Button>
              </RoyTooltip>
            </div>
          )}
        </div>
      ), // Custom cell components!
    },
    {
      Header: "Action",
      accessor: "action",
      className: "text-center",
      headerClassName: "react-table-header-class",
      sortable: false,
      filterable: false,
      width: 320,
      Cell: (props) => (
        <div className="react-action-class">
          <RoyTooltip
            id={`view-${props.original.id}`}
            title={"View"}
            placement="left"
          >
            <Button
              className="c-btn c-primary mr-10"
              style={{ minWidth: "50px" }}
              onClick={() => {
                setView(true);
                setModal(true);
                setData(props.original);
              }}
              id={`view-${props.original.id}`}
            >
              <div className="fs-14 medium-text">
                <i className="fas fa-info" />
              </div>
            </Button>
          </RoyTooltip>
          <RoyTooltip
            id={`edit-${props.original.id}`}
            title={"Edit"}
            placement="left"
          >
            <Button
              style={{ minWidth: "50px" }}
              className="c-btn c-success mr-10"
              onClick={() => formAction("edit", props.original)}
              id={`edit-${props.original.id}`}
            >
              <div className="fs-14 medium-text">
                <i className="fas fa-edit" />
              </div>
            </Button>
          </RoyTooltip>
          <RoyTooltip
            id={`delete-${props.original.id}`}
            title={"Delete"}
            placement="right"
          >
            <Button
              style={{ minWidth: "50px" }}
              className="c-btn c-danger mr-10"
              onClick={() => {
                // setView(true);
                setDeleteModal(true);
                setData(props.original);
              }}
              id={`delete-${props.original.id}`}
            >
              <div className="fs-14 medium-text">
                <i class="fa fa-trash" aria-hidden="true"></i>
              </div>
            </Button>
          </RoyTooltip>
        </div>
      ), // Custom cell components!
    },
  ];

  const [tblData, settblData] = useState();
  const [loader, setLoader] = useState(false);
  const [pages, setPages] = useState(0);
  const [activePage, setActivePage] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [field, setField] = useState("created_at");
  const [sortBy, setSortBy] = useState("DESC");
  const [modal, setModal] = useState(false);
  const [data, setData] = useState({});
  const [view, setView] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  const toaster = useRef();

  const {
    sidebarTheme: { activeColor },
  } = props;

  useEffect(() => {
    getList(field, sortBy, searchText, activePage + 1);
    setPages(10);
  }, []);

  const activeInactiveStatusHandler = (user) => {
    setLoader(true);
    Api(
      "GET",
      `chef/status?id=${user.id}&is_active=${user.is_active === true ? 0 : 1}`
    )
      .then((response) => {
        if (response.status) {
          getList(field, sortBy, searchText, activePage + 1);
          setLoader(false);
        }
      })
      .catch((error) => {
        if (toaster.current) toaster.current.error(error);
        setLoader(false);
      });
  };

  const onChangePageGetData = (state) => {
    if (state.page !== activePage) {
    }
  };

  const formAction = (action, data = null) => {
    // Here you can view the data and make forward action for edit data
    if (action === "add") {
      props.history.push("/chef/add");
    } else if (action === "edit") {
      props.history.push(`/chef/edit/${data.id}`);
    }
  };

  function getList(
    field = "created_at",
    sortBy = "DESC",
    search = "",
    page = "1"
  ) {
    const params = {
      sort_by: field,
      order: sortBy,
      search: search,
      page: page,
    };
    setLoader(true);

    Api("POST", "chef/list", params)
      .then((res) => {
        if (res.data.status) {
          settblData(res.data.data.rows);
          setPages(Math.ceil(res.data.data.count / TABLE.LIMIT));
          setLoader(false);
        } else {
          setLoader(false);
          if (toaster.current) toaster.current.error(res.data.message);
        }
      })
      .catch((err) => {
        setLoader(false);
        if (toaster.current) toaster.current.error(err.response);
      });
  }

  return (
    <div>
      <div className="plr-15">
        <div className="roe-card-style mtb-15">
          <div className="roe-card-header module-header">
            <div className="flex-1 fs-16 demi-bold-text" />
            <div className="mr-10">
              <input
                value={searchText}
                onChange={(e) => {
                  setSearchText(e.target.value);
                  const search = e.target.value;
                  setLoader(true);
                  setActivePage(0);
                  setTimeout(() => {
                    getList(field, sortBy, search, 1);
                  }, 500);
                }}
                type="text"
                placeholder="Search..."
                className="fs-14 medium-text plr-10 form-control react-form-input"
              />
            </div>
            <div>
              <Button
                className="c-btn ma-5"
                onClick={() => formAction("add")}
                style={{
                  backgroundColor: activeColor,
                  color: "white",
                }}
              >
                <i className="fas fa-plus mr-10" />
                Add Chef
              </Button>
            </div>
          </div>
          <div className="roe-card-body">
            <ReactTable
              style={{
                border: "none",
                boxShadow: "none",
              }}
              data={tblData}
              columns={columns}
              defaultPageSize={10}
              filterable
              minRows={2}
              manual
              defaultFilterMethod={(filter, row) => {
                const id = filter.pivotId || filter.id;

                return row[id] !== undefined
                  ? String(row[id].toLowerCase()).includes(
                      filter.value.toLowerCase()
                    )
                  : true;
              }}
              className="-striped -highlight custom-react-table-theme-class"
              pages={pages}
              page={activePage}
              PaginationComponent={Pagination}
              loading={loader}
              LoadingComponent={Loader}
              onFetchData={onChangePageGetData}
              onPageChange={(pageIndex) => {
                setActivePage(pageIndex);
                getList(field, sortBy, searchText, pageIndex + 1);
              }}
              onSortedChange={(sortProperties) => {
                const sort = sortProperties[0].desc ? "DESC" : "ASC";
                setSortBy(sort);
                setActivePage(0);
                setField(sortProperties[0].id);
                getList(sortProperties[0].id, sort, searchText, 1);
              }}
            />
          </div>

          {view && (
            <CommonModel
              modal={modal}
              size="lg"
              title="Admin information"
              style={{ maxWidth: "800px", width: "80%" }}
              children={
                <View
                  data={data}
                  toggle={() => {
                    setModal(false);
                  }}
                />
              }
            />
          )}
          {deleteModal && (
            <CommonModel
              modal={deleteModal}
              size="lg"
              title="Admin information"
              style={{ maxWidth: "500px", width: "80%" }}
              children={
                <Delete
                  data={data}
                  toggle={() => {
                    setDeleteModal(false);
                    getList(field, sortBy, searchText, 1);
                  }}
                />
              }
            />
          )}
        </div>
      </div>
      <Toaster ref={toaster} />
    </div>
  );
};

export default withRouter(ChefTable);
