import { withFormik } from "formik";
import * as Yup from "yup";

const formikEnhancer = withFormik({
  validationSchema: Yup.object().shape({
    first_name: Yup.string()
      .trim()
      .required("This field is required"),
    last_name: Yup.string()
      .trim()
      .required("This field is required"),
    email: Yup.string()
      .email("Please enter valid email")
      .trim()
      .required("This field is required"),
    password: Yup.string().when("isEdit", {
      is: false,
      then: Yup.string().required("This field is required"),
    }),
    confirmPassword: Yup.string().when("isEdit", {
      is: false,
      then: Yup.string().required("This field is required"),
    }),
  }),
  validate: (values) => {
    const errors = {};
    if (values.password && values.password !== values.confirmPassword) {
      errors.confirmPassword = "Password not matched";
    }
    return errors;
  },
  mapPropsToValues: (props) => ({
    id:
      props && props.editedData && props.editedData.id
        ? props.editedData.id
        : "",
    first_name:
      props && props.editedData && props.editedData.first_name
        ? props.editedData.first_name
        : "",
    last_name:
      props && props.editedData && props.editedData.last_name
        ? props.editedData.last_name
        : "",

    email:
      props && props.editedData && props.editedData.email
        ? props.editedData.email
        : "",
    profilePhoto:
      props && props.editedData && props.editedData.profile_photo
        ? props.editedData.profile_photo
        : "",
  }),
  handleSubmit: (values) => { },
  displayName: "CustomValidationForm",
  enableReinitialize: true,
});

export default formikEnhancer;
