import { withFormik } from "formik";
import * as Yup from "yup";

const formikEnhancer = withFormik({
  validationSchema: Yup.object().shape({
    // mealType: Yup.string()
    //   .trim()
    //   .required("This field is required"),
    // mealIcon: Yup.string()
    //   .nullable()
    //   .required("This field is required"),
  }),
  mapPropsToValues: (props) => ({
    id:
      props && props.editedData && props.editedData.id
        ? props.editedData.id
        : "",
    mealType:
      props && props.editedData && props.editedData.meal_type
        ? props.editedData.meal_type
        : "",
    mealIcon:
      props && props.editedData && props.editedData.image
        ? props.editedData.image
        : "",
  }),
  handleSubmit: (values) => { },
  displayName: "CustomValidationForm",
  enableReinitialize: true,
});

export default formikEnhancer;
