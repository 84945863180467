import React, { useState } from "react";
import { compose } from "redux";
import enhancer from "./Validator";
import SmallLoader from "../common/SmallLoader";
import Loader from "components/common/Loader";
import { ImageResolution } from "../../helper/constant";
import Placeholder from "../../assets/images/Logo.png";
const ChefForm = (props) => {
  const {
    handleChange,
    handleBlur,
    errors,
    touched,
    submitCount,
    onSubmit,
    values,
    action,
    setFieldTouched,
    setFieldValue,
  } = props;

  const [uploadImage, setUploadImage] = useState(null);

  const Error = (props) => {
    const field1 = props.field;
    if ((errors[field1] && touched[field1]) || submitCount > 0) {
      return (
        <span className={props.class ? props.class : "error-msg"}>
          {errors[field1]}
        </span>
      );
    } else {
      return <span />;
    }
  };

  const handleSubmit = () => {
    let { values, isValid, handleSubmit } = props;

    if (isValid) {
      onSubmit(values, action);
    }
    handleSubmit();
  };

  const handleImage = (e, setValue) => {
    if (e.target.files[0]) {
      let fileObj = e.target.files[0];

      if (fileObj) {
        const img = new Image();

        img.src = window.URL.createObjectURL(fileObj);

        img.onload = function() {
          const width = img.naturalWidth,
            height = img.naturalHeight;

          window.URL.revokeObjectURL(img.src);
          setValue("width", width);
          setValue("height", height);
          setFieldTouched("mealIcon", true, true);
          if (
            width >= ImageResolution.WIDTH &&
            height >= ImageResolution.HEIGHT
          ) {
            setValue("mealIcon", fileObj);
            setUploadImage(URL.createObjectURL(fileObj));
          }
        };
      } else {
        //No file was input or browser doesn't support client side reading
        // form.submit();
      }
    } else {
      setUploadImage(null);
    }
  };

  return (
    <div>
      {!props.loader ? (
        <form>
          <div className="form-group">
            <label className="fs-16 medium-text">Type</label>
            <input
              type="text"
              className="form-control react-form-input"
              id="mealType"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.mealType}
              placeholder="Meal Type"
            />
            <Error field="mealType" />
          </div>
          <div>
            <div className="form-group">
              <label className="fs-16 medium-text">Meal Icon</label>
              <br />
              <div className="file-upload">
                <label className="c-btn c-primary form-button fs-16 demi-bold-text mt-15">
                  Upload image
                  <input
                    id="mealIcon"
                    className="file-upload__input"
                    name="file-upload"
                    type="file"
                    accept="image/jpg,image/png,image/jpeg"
                    onChange={(e) => handleImage(e, setFieldValue)}
                    onBlur={handleBlur}
                  />
                </label>
              </div>
              <br />
              {
                <img
                  src={
                    uploadImage
                      ? uploadImage
                      : values.mealIcon
                      ? values.mealIcon
                      : Placeholder
                  }
                  alt=""
                  className="mtb-15"
                  style={{
                    width: "200px",
                    borderRadius: "4%",
                    height: "150px",
                    background: "#404040",
                  }}
                />
              }
              <br />
              <Error field="mealIcon" />
            </div>
          </div>
          <div>
            <div>
              {!props.loading && (
                <button
                  type="button"
                  className="c-btn c-info form-button fs-16 demi-bold-text"
                  style={{ maxWidth: "125px" }}
                  onClick={() => handleSubmit()}
                  disabled={props.disabled}
                >
                  Submit
                </button>
              )}
              {props.loading && <SmallLoader />}
            </div>
          </div>
        </form>
      ) : (
        <Loader loading={props.loader} />
      )}
    </div>
  );
};

export default compose(enhancer)(ChefForm);
