import React from "react";
import StyleWrapper from "./style";
import PageTitle from "components/common/PageTitle";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

const Dashboard = (props) => {
  return (
    <StyleWrapper {...props}>
      <PageTitle title="sidebar.dashboard" className="plr-15" />
      <div className="font-weight-bold d-flex justify-content-center align-items-center div-height">
        Dashboard analytics will place here . . .
      </div>
    </StyleWrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    ...state.themeChanger,
  };
};

export default connect(
  mapStateToProps,
  withRouter,
  null
)(Dashboard);
