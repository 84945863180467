export const sidebarData = [
  {
    name: "sidebar.dashboard",
    iconClass: "fas fa-chalkboard",
    routepath: "/dashboard",
  },
  {
    name: "sidebar.admin",
    iconClass: "far fa-user",
    routepath: "/admin",
  },
  {
    name: "sidebar.staticpage",
    iconClass: "fa fa-clone",
    routepath: "/static-page",
  },
  {
    name: "sidebar.user",
    iconClass: "fa fa-user-circle",
    routepath: "/user",
  },
  {
    name: "sidebar.chef",
    iconClass: "fa fa-user-circle",
    routepath: "/chef",
  },
  {
    name: "sidebar.meal",
    iconClass: "fas fa-apple-alt",
    routepath: "/meal",
  },
  {
    name: "sidebar.class",
    iconClass: "fa fa-calendar",
    routepath: "/class",
  },
  {
    name: "sidebar.ingredients",
    iconClass: "fa fa-folder",
    routepath: "/ingredients",
  },
  {
    name: "sidebar.subscription",
    iconClass: "fa fa-check-square",
    routepath: "/subscription",
  },
  {
    name: "sidebar.blokprice",
    iconClass: "fa fa-check-square",
    routepath: "/blok-price",
  },
  {
    name: "sidebar.subscribe",
    iconClass: "fa fa-check-square",
    routepath: "/subscription-details",
  },
];

// Comments:::::::

//  If you want one level child then look below example

/*
  {
    name: 'sidebar.forms',
    iconClass: 'fab fa-wpforms',
    child: [
      {
        listname: 'sidebar.regularforms',
        routepath: '/regularform',
        shortname: 'RF'
      }
    ]
  }
*/

//  If you want Second level child then look below example

/*
   {
      name: 'sidebar.pages',
      iconClass: 'fas fa-print',
      child: [
        {
          listname: 'sidebar.authentication',
          iconClass: 'fas fa-user',
          child: [
            {
              listname: 'sidebar.login',
              routepath: '/login',
              shortname: 'L'
            },
          ]
        }
      ]
    }
*/
