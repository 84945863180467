import React, { useRef, useState } from "react";
import UserWrapper from "./user.style";
import PageTitle from "components/common/PageTitle";
import { connect } from "react-redux";
import ChangePasswordForm from "../../components/changepassword/ChangePasswordForm";
import Toaster from "../../components/common/Toaster";
import Api from "helper/Api";
const ChangePassword = (props) => {
  const toaster = useRef();
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const submitFormHandler = (data) => {
    const params = {
      old_password: data.currentPassword,
      new_password: data.newPassword,
    };
    setDisabled(true);
    setLoading(true);
    Api("PUT", "auth/change-password", params)
      .then((response) => {
        if (response.data.status) {
          if (toaster.current) toaster.current.success(response.data.message);
          setLoading(false);
          setTimeout(() => {
            // Api("PUT","auth/change-password",params)
            props.history.push("/login");
          }, 500);
        } else {
          if (toaster.current) toaster.current.error(response.data.message);
          setDisabled(false);
          setLoading(false);
        }
      })
      .catch((error) => {
        if (toaster.current) toaster.current.error(error.response.data.message);
        setDisabled(false);
        setLoading(false);
      });
  };

  return (
    <UserWrapper {...props}>
      <div className="pos-relative">
        <PageTitle title="sidebar.changepassword" className="plr-15" />
        <div
          className="back-icon fs-15 demi-bold-text cursor-pointer"
          onClick={() => props.history.push("/")}
        >
          <i className="fas fa-step-backward"></i> Back
        </div>
      </div>
      <div className="plr-15">
        <div className="roe-card-style mtb-15">
          <div className="roe-card-header module-header"></div>
          <div className="roe-card-body">
            <ChangePasswordForm
              loading={loading}
              disabled={disabled}
              onSubmit={submitFormHandler}
            />
          </div>
        </div>
      </div>
      <Toaster ref={toaster} />
    </UserWrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    ...state.themeChanger,
  };
};

export default connect(mapStateToProps, null)(ChangePassword);
