import axios from "axios";
import { baseUrl } from "./constant";
import { store } from "redux/store";

const Api = (method, urlData, params) => {
  const url = `${baseUrl}${urlData}`;
  const headers = {};

  if (store.getState().auth.accessToken) {
    headers["Authorization"] = store.getState().auth.accessToken;
  }
  let axiosObj = {
    method: method,
    url: url,
    data: params,
    headers: headers,
  };

  return axios(axiosObj);
};

export default Api;
