import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import { routerReducer, routerMiddleware } from "react-router-redux";
import { createBrowserHistory } from "history";
import thunk from "redux-thunk";
import { persistReducer, persistStore } from "redux-persist";
import reducers from "../redux/reducers";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";
import storage from "redux-persist/lib/storage";
import authReducer from "./auth/reducer";
import fileReducer from "./fileUpload/reducer";
// import createEncryptor from "redux-persist-transform-encrypt";

// const encryptor = createEncryptor({
//   secretKey: "my-super-secret-key",
//   onError: function(error) {
//     // Handle the error.
//   }
// });

const persistConfig = {
  key: "root",
  storage,
  // stateReconciler: autoMergeLevel2,
  // transforms: [encryptor]
  blacklist: ["LanguageSwitcher", "themeChanger", "themeSetting"],
};

const history = createBrowserHistory({
  basename: process.env.REACT_APP_BASE_NAME,
});
const routeMiddleware = routerMiddleware(history);
const middlewares = [thunk, routeMiddleware];
const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const reducer = combineReducers({
  ...reducers
});

const rootReducer = (state, action) => {
  // if (action.type === LOGOUT) {
  // 	localStorage.removeItem("persist:root");
  // 	return reducer(undefined, action);
  // }
  return reducer(state, action);
};

// const store = createStore(
//   combineReducers({
//     ...reducers,
//     // router: routerReducer,
//     // auth: persistReducer(persistConfig, authReducer),
//     // file: persistReducer(persistConfig, fileReducer),
//   }),
//   composeEnhancer(applyMiddleware(...middlewares))
// );
const persistedReducer = persistReducer(persistConfig, rootReducer);

let store = createStore(persistedReducer)

const persistor = persistStore(store);

store.subscribe(() => {
  sessionStorage.setItem("accessToken", store.getState().auth.accessToken);
  sessionStorage.setItem("isLogin", store.getState().auth.isLogin);
  // localStorage.setItem("name", store.getState().auth.name);
  // localStorage.setItem("email", store.getState().auth.email);
});

export { store, history, persistor };
