import React from "react";
import { compose } from "redux";
import enhancer from "./Validator";
import SmallLoader from "../common/SmallLoader";
import Loader from "components/common/Loader";
const SubscriptionForm = (props) => {
  const {
    handleChange,
    handleBlur,
    errors,
    touched,
    submitCount,
    onSubmit,
    values,
    action,
  } = props;

  const Error = (props) => {
    const field1 = props.field;
    if ((errors[field1] && touched[field1]) || submitCount > 0) {
      return (
        <span className={props.class ? props.class : "error-msg"}>
          {errors[field1]}
        </span>
      );
    } else {
      return <span />;
    }
  };

  const handleSubmit = () => {
    let { values, isValid, handleSubmit } = props;

    if (isValid) {
      onSubmit(values, action);
    }
    handleSubmit();
  };

  return (
    <div>
      {!props.loader ? (
        <form>
          <div className="form-group">
            <label className="fs-16 medium-text">Title</label>
            <input
              type="text"
              className="form-control react-form-input"
              id="title"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.title}
              placeholder="Title"
            />
            <Error field="title" />
          </div>

          <div className="form-group">
            <label className="fs-16 medium-text">Display amount</label>
            <input
              type="text"
              className="form-control react-form-input"
              id="dAmount"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.dAmount}
              placeholder="300/month"
            />
            <Error field="dAmount" />
          </div>

          <div className="form-group">
            <label className="fs-16 medium-text">Amount</label>
            <input
              type="text"
              className="form-control react-form-input"
              id="amount"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.amount}
              placeholder="Amount"
            />
            <Error field="amount" />
          </div>

          <div className="form-group">
            <label className="fs-16 medium-text">Timeframe</label>
            <input
              type="text"
              className="form-control react-form-input"
              id="timeframe"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.timeframe}
              placeholder="Timeframe should be in month. i.e. 12"
            />
            <Error field="timeframe" />
          </div>

          <div>
            <div>
              {!props.loading && (
                <button
                  type="button"
                  className="c-btn c-info form-button fs-16 demi-bold-text"
                  style={{ maxWidth: "125px" }}
                  onClick={() => handleSubmit()}
                  disabled={props.disabled}
                >
                  Submit
                </button>
              )}
              {props.loading && <SmallLoader />}
            </div>
          </div>
        </form>
      ) : (
        <Loader loading={props.loader} />
      )}
    </div>
  );
};

export default compose(enhancer)(SubscriptionForm);
