import React from "react";

const viewModal = (props) => {
  let data = props.data;
  return (
    <div className="wp-100">
      <div
        className="fs-15 demi-bold-text cursor-pointer float-right"
        onClick={() => props.toggle()}
      >
        <i className="fa fa-times"></i>
      </div>
      <div className=" fs-20 font-weight-bolder">
        Subscription plan information
      </div>
      <hr />
      <div className="row">
        <div className="col-6 pt-4">
          <div>
            <div style={{ fontWeight: "bolder", textAlign: "left" }}>Title</div>
            <div className="text-left">{`${data.title}`}</div>
          </div>
        </div>
        <div className="col-6 pt-4">
          <div>
            <div style={{ fontWeight: "bolder", textAlign: "left" }}>
              Amount
            </div>
            <div className="text-left">{`${data.amount}`}</div>
          </div>
        </div>

        <div className="col-6 pt-4">
          <div>
            <div style={{ fontWeight: "bolder", textAlign: "left" }}>
              Display Amount
            </div>
            <div className="text-left">{`${
              data.display_amount ? data.display_amount : "--"
            }`}</div>
          </div>
        </div>
        <div className="col-6 pt-4">
          <div>
            <div style={{ fontWeight: "bolder", textAlign: "left" }}>Time</div>
            <div className="text-left">{`${
              data.timeframe
                ? `${data.timeframe} ${
                    data.timeframe.toString() === "1" ? "Month" : "Months"
                  }`
                : "--"
            }`}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default viewModal;
