import React from "react";
import Placeholder from "../../assets/images/Logo.png";

const viewModal = (props) => {
  let data = props.adminData;
  return (
    <div className="wp-100">
      <div
        className="fs-15 demi-bold-text cursor-pointer float-right"
        onClick={() => props.toggle()}
      >
        <i className="fa fa-times"></i>
      </div>
      <div className=" fs-20 font-weight-bolder">Admin information</div>
      <hr />
      <div className="row">
        <div className="col-12 pt-4">
          <div>
            <div style={{ fontWeight: "bolder", textAlign: "left" }}>
              Profile Image
            </div>
            <img
              src={data.profile_image ? data.profile_image : Placeholder}
              alt=""
              height="150px"
              width="150px"
              style={{ borderRadius: "6%" }}
            />
          </div>
        </div>
        <div className="col-6 pt-4">
          <div>
            <div style={{ fontWeight: "bolder", textAlign: "left" }}>Name</div>
            <div className="text-left">{`${data.first_name} ${data.last_name}`}</div>
          </div>
        </div>
        <div className="col-6 pt-4">
          <div>
            <div style={{ fontWeight: "bolder", textAlign: "left" }}>Email</div>
            <div className="text-left">{`${data.email}`}</div>
          </div>
        </div>
        <div className="col-6 pt-4">
          <div>
            <div style={{ fontWeight: "bolder", textAlign: "left" }}>Role</div>
            <div className="text-left">{`${data.admin_role.role}`}</div>
          </div>
        </div>
        <div className="col-6 pt-4">
          <div>
            <div style={{ fontWeight: "bolder", textAlign: "left" }}>
              Currently active
            </div>
            <div className="text-left">{`${
              data.is_active ? "Yes" : "No"
            }`}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default viewModal;
