import React from "react";
import { compose } from "redux";
import enhancer from "./validator";
import "./StaticPageForm.css";
import CKEditor from "ckeditor4-react";
import { config } from "../../util/editorconfig";
import SmallLoader from "../common/SmallLoader";
import Loader from "components/common/Loader"

const StaticPageForm = (props) => {

  const {
    handleChange,
    handleBlur,
    errors,
    setFieldTouched,
    setFieldValue,
    touched,
    submitCount,
    onSubmit,
    values,
    action,
  } = props;


  const Error = (props) => {
    const field1 = props.field;
    if ((errors[field1] && touched[field1]) || submitCount > 0) {
      return (
        <span className={props.class ? props.class : "error-msg"}>
          {errors[field1]}
        </span>
      );
    } else {
      return <span />;
    }
  };

  const handleSubmit = () => {
    let { values, isValid, handleSubmit } = props;

    if (isValid) {
      onSubmit(values, action);
    }
    handleSubmit();
  };

  return (
    <div>

      {!props.loader ?
        <form>

          <div className="form-group">
            <label className="fs-16 medium-text">
              Title
            </label>
            <input
              type="text"
              className="form-control react-form-input"
              id="title"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.title}
              placeholder="Title"
            />
            <Error field="title" />
          </div>

          <div className="form-group">
            <label className="fs-16 medium-text">
              Content
            </label>
            <CKEditor
              id="contentEn"
              onBeforeLoad={(CKEDITOR) => (CKEDITOR.disableAutoInline = true)}
              config={config}
              data={values.content}
              onChange={(event) => {
                setFieldValue("content", event.editor.getData());
              }}
              onBlur={(event) => {
                setFieldTouched("content", true, true);
              }}
            />
            <Error field="content" />
          </div>
          <div>
            <div>
              {!props.loading && <button
                type="button"
                className="c-btn c-info form-button fs-16 demi-bold-text"
                style={{ maxWidth: "125px" }}
                onClick={() => handleSubmit()}
                disabled={props.disabled}
              >
                Submit
              </button>}
              {
                props.loading && <SmallLoader />
              }
            </div>
          </div>
        </form>

        : <Loader loading={props.loader} />}


    </div>

  );
};

export default compose(enhancer)(StaticPageForm);
