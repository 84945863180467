import { withFormik } from "formik";
import * as Yup from "yup";

const formikEnhancer = withFormik({
  validationSchema: Yup.object().shape({
    name: Yup.string()
      .trim()
      .required("This field is required"),
    // email: Yup.string()
    //   .email()
    //   .trim()
    //   .required("This field is required"),
    // phone: Yup.string()
    //   .min(10, "Phone number must be a 10 character")
    //   .max(10, "Phone number must be a 10 character")
    //   .required("This field is required"),
    // bio: Yup.string()
    //   .trim()
    //   .required("This field is required"),
    // profilePhoto: Yup.string()
    //   .nullable()
    //   .required("This field is required"),
  }),
  mapPropsToValues: (props) => ({
    id:
      props && props.editedData && props.editedData.id
        ? props.editedData.id
        : "",
    name:
      props && props.editedData && props.editedData.name
        ? props.editedData.name
        : "",
    email:
      props && props.editedData && props.editedData.email
        ? props.editedData.email
        : "",
    profilePhoto:
      props && props.editedData && props.editedData.profile_photo
        ? props.editedData.profile_photo
        : "",
    bio:
      props && props.editedData && props.editedData.bio
        ? props.editedData.bio
        : "",
    phone:
      props && props.editedData && props.editedData.phone_number
        ? props.editedData.phone_number
        : "",
  }),
  handleSubmit: (values) => {},
  displayName: "CustomValidationForm",
  enableReinitialize: true,
});

export default formikEnhancer;
