import React, { useRef } from "react";
import { compose } from "redux";
import enhancer from "./Validator";
import SmallLoader from "../common/SmallLoader";
import Loader from "components/common/Loader";
import Toaster from "../common/Toaster";
import { withRouter } from "react-router";
// import CKEditor from "ckeditor4-react";
// import { config } from "../../util/editorconfig";
import "@pathofdev/react-tag-input/build/index.css";

const IngredientsForm = (props) => {
  const {
    handleChange,
    handleBlur,
    errors,
    touched,
    submitCount,
    onSubmit,
    values,
    action,
  } = props;

  const toaster = useRef();

  const Error = (props) => {
    const field1 = props.field;
    if ((errors[field1] && touched[field1]) || submitCount > 0) {
      return (
        <span className={props.class ? props.class : "error-msg"}>
          {errors[field1]}
        </span>
      );
    } else {
      return <span />;
    }
  };

  const handleSubmit = () => {
    let { values, isValid, handleSubmit } = props;

    if (isValid) {
      onSubmit(values, action);
    }
    handleSubmit();
  };

  return (
    <div>
      {!props.loader ? (
        <form>
          <div className="form-group">
            <label className="fs-16 medium-text">Name</label>
            <input
              type="text"
              className="form-control react-form-input"
              id="name"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.name}
              style={{ textTransform: "capitalize" }}
              placeholder="Name"
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  handleSubmit()
                }
              }}
            />
            <Error field="name" />
          </div>
          <div>
            <div>
              {!props.loading && (
                <button
                  type="button"
                  className="c-btn c-info form-button fs-16 demi-bold-text"
                  style={{ maxWidth: "125px" }}
                  onClick={() => handleSubmit()}
                  disabled={props.disabled}
                >
                  Submit
                </button>
              )}
              {props.loading && <SmallLoader />}
            </div>
          </div>
        </form>
      ) : (
        <Loader loading={props.loader} />
      )}
      <Toaster ref={toaster} />
    </div>
  );
};

export default withRouter(compose(enhancer)(IngredientsForm));
