import { withFormik } from "formik";
import * as Yup from "yup";

const formikEnhancer = withFormik({
  validationSchema: Yup.object().shape({
    title: Yup.string()
      .trim()
      .required("This field is required"),
    dAmount: Yup.string()
      .trim()
      .required("This field is required"),
    amount: Yup.string()
      .trim()
      .required("This field is required"),
    timeframe: Yup.string()
      .trim()
      .required("This field is required"),
  }),
  mapPropsToValues: (props) => ({
    id:
      props && props.editedData && props.editedData.id
        ? props.editedData.id
        : "",
    title:
      props && props.editedData && props.editedData.title
        ? props.editedData.title
        : "",
    dAmount:
      props && props.editedData && props.editedData.display_amount
        ? props.editedData.display_amount
        : "",

    timeframe:
      props && props.editedData && props.editedData.timeframe
        ? props.editedData.timeframe
        : "",
    amount:
      props && props.editedData && props.editedData.amount
        ? props.editedData.amount
        : "",
  }),
  handleSubmit: (values) => {},
  displayName: "CustomValidationForm",
  enableReinitialize: true,
});

export default formikEnhancer;
